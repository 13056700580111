import type { ReactNode } from "react";
import { createContext, useContext, useState } from "react";
import type {
  IntercomProviderProps,
  IntercomContextValues as BaseIntercomContextValues,
} from "react-use-intercom";
import {
  IntercomProvider as BaseIntercomProvider,
  useIntercom as useBaseIntercom,
} from "react-use-intercom";
import { enableIntercom } from "~/constants";
import { useIsImpersonatedSession } from "~/utils/use-is-impersonated-session";

interface IntercomContextValues {
  isBlocked: boolean;
  intercom: BaseIntercomContextValues | null;
}

const IntercomContext = createContext<IntercomContextValues>({
  isBlocked: true,
} as IntercomContextValues);

export function useIntercom(): IntercomContextValues {
  return useContext(IntercomContext);
}

function IsIntercomBlockedProvider({
  isBlocked,
  children,
}: {
  isBlocked: boolean;
  children: ReactNode;
}): JSX.Element {
  // eslint-disable-next-line react-hooks/rules-of-hooks -- useIntercom doesn't exist unless enableIntercom is true
  const intercom = isBlocked ? null : useBaseIntercom();
  return (
    <IntercomContext.Provider value={{ isBlocked, intercom }}>{children}</IntercomContext.Provider>
  );
}

export function IntercomProvider(
  props: Omit<React.PropsWithChildren<IntercomProviderProps>, "appId">
): JSX.Element {
  const [isBlocked, setIsBlocked] = useState(true);
  const isImpersonated = useIsImpersonatedSession();

  if (enableIntercom && !isImpersonated) {
    return (
      <BaseIntercomProvider
        {...props}
        appId="fifunvsp"
        autoBoot
        onUnreadCountChange={(): void => {
          // This function always runs at least once when Intercom is loaded
          // https://forum.intercom.com/s/question/0D52G00004WxWMgSAN/how-can-i-check-if-an-adblocker-has-prevented-the-messenger-script-from-loading
          setIsBlocked(false);
        }}
      >
        <IsIntercomBlockedProvider isBlocked={isBlocked}>
          {props.children}
        </IsIntercomBlockedProvider>
      </BaseIntercomProvider>
    );
  }

  return (
    <IsIntercomBlockedProvider isBlocked={isBlocked}>{props.children}</IsIntercomBlockedProvider>
  );
}
